module stratos.common.configurations {
    export class themeConfiguration {

        static $inject = ['$mdThemingProvider'];
        constructor($mdThemingProvider: angular.material.IThemingProvider) {

            var customBlueMap = $mdThemingProvider.extendPalette('light-blue', {
                'contrastDefaultColor': 'light',
                'contrastDarkColors': ['50'],
                '50': 'ffffff',                           
                //'800': '#003650'   // 'Sherpa Green'  
                //'800': '#194070'    
                '800': '#195080'       
            });

            $mdThemingProvider.definePalette('customBlue', customBlueMap);

            var customCyanMap = $mdThemingProvider.extendPalette('cyan', {
                'contrastDefaultColor': 'light',
                'contrastDarkColors': ['50'],
                '50': 'ffffff',
                '700': '#33709B'
                //'700': '#0759A4'                
            });
            //#ABC4D7
            //#BFD2E1
            //#D3E0EB
            //#E7EEF5

            $mdThemingProvider.definePalette('customCyan', customCyanMap);


            $mdThemingProvider.theme('default')
                .primaryPalette('customBlue', {
                    'default': '800',
                    'hue-1': '50'
                })
                .accentPalette('customCyan', {
                    'default': '700',
                    'hue-1': '50'
                })

            $mdThemingProvider.theme('input', 'default')
                .primaryPalette('grey');

            $mdThemingProvider.theme("custom-toast");
        }
    }
}